import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FilterBox } from '../../../../ui'
import { PUBLIC_OFFER_FILTERS } from './public-offer-filter.constants'
import { AddGeneralTermsBtn, AddPublicOfferBtn } from '../buttons'

export const PublicOfferFilter = () => {
  const { formatMessage } = useIntl()
  const permissions = useSelector((state) => state.global.permissions)
  const combos = useSelector((state) => state.combos)
  const filters = PUBLIC_OFFER_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    if (item.values) {
      return {
        ...item,
        label: formatMessage({
          id: 'pages.public.offer.filters.' + item.name + '.title',
        }),
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.public.offer.filters.' + item.name + '.title',
      }),
    }
  })
  return (
    <FilterBox filters={filters}>
      {permissions['editar_op'] ? (
        <Box display="flex" justifyContent="flex-end">
          <AddGeneralTermsBtn />
          <Box ml={'1em'}>
            <AddPublicOfferBtn />
          </Box>
        </Box>
      ) : null}
    </FilterBox>
  )
}
