import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { useModalContext } from '../../../../ui/modal'
import { AssignToDialog } from '../dialogs'
import { useApplicationsCalls } from '../../hooks/application-calls.hook'
import { ConfirmationDialog } from '../../../../ui'
import { requests } from '../../../../core/ajax'
import feedback from '../../../../core/feedback'
import { SwitchTeam } from '../dialogs/switch-team.component'

export const TableActions = (props) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { put } = requests
  const {
    actions: { open, close },
  } = useModalContext()
  const userRole = useSelector((state) => state.global.role)
  const operaciones = userRole === 'gestor' || userRole === 'delegado'
  const appId = props.rowData['id']
  const solicitud_id = props.rowData['solicitud_id']
  const { getApplications, getGestorGc } = useApplicationsCalls()
  const searchParams = useSelector((state) => state.applications.search)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget)

  const handleCloseMenu = () => setAnchorEl(null)

  const handleGoToDetail = useCallback(
    (e, row) => {
      setAnchorEl(null)
      const props = {
        pathname: `/applications/${appId}`,
        state: { prevSearch: history.location.search },
      }
      history.push(props)
    },
    [history, appId]
  )

  const handleOpenModal = (props) => {
    setAnchorEl(null)
    open({
      Component: AssignToDialog,
      data: { selectedRow: appId, getApplications, history },
    })
  }

  const switchApp = (gestor_id = 0) => {
    put(`/solicitudes/${appId}/switch_team`, { gestor_id })
      .then(() => {
        getApplications(searchParams)
        feedback(
          'success',
          formatMessage({ id: 'pages.applications.feedback.5' }, { id: solicitud_id })
        )
        close()
      })
      .catch(() => {
        feedback('error', formatMessage({ id: 'pages.applications.feedback.4' }))
        close()
      })
  }

  const handleAssign = async () => {
    setAnchorEl(null)
    if (operaciones) {
      const data = await getGestorGc(appId)
      if (data.gestor) {
        const gestorData = data.gestor.nombre + ' ' + data.gestor.apellidos
        open({
          Component: ConfirmationDialog,
          data: {
            title: formatMessage({ id: 'pages.applications.table.confirm.title.label' }),
            gestor: gestorData,
            text: formatMessage({ id: 'pages.applications.table.confirm.text.label' }),
            yesText: formatMessage({ id: 'global.accept' }),
            noText: formatMessage({ id: 'global.cancel' }),
            yesAction: switchApp,
            yesAndClose: false,
          },
          type: 'centered',
        })
      } else {
        open({
          Component: SwitchTeam,
          data: { handleAssign: switchApp, role: 'gestor_gc' },
        })
      }
    } else if (userRole === 'gestor_gc') {
      open({
        Component: SwitchTeam,
        data: { handleAssign: switchApp, role: 'gestor' },
      })
    }
  }

  const isReassignable = true

  return (
    <>
      <IconButton onClick={handleOpenMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={handleGoToDetail}>
          {formatMessage({
            id: `pages.applications.table.buttons.viewDetail${
              userRole === 'gestor' || userRole === 'delegado' ? '1' : '2'
            }.label`,
          })}
        </MenuItem>
        {isReassignable && (userRole === 'delegado' || userRole === 'gestor_gc') && (
          <MenuItem onClick={handleOpenModal}>
            {formatMessage({ id: 'pages.applications.table.buttons.reassign.label' })}
          </MenuItem>
        )}
        {(operaciones || userRole === 'gestor_gc') && (
          <MenuItem onClick={handleAssign}>
            {formatMessage({
              id: `pages.applications.table.buttons.assign_${operaciones ? 'gc' : 'opp'}.label`,
            })}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
