export const COMMS_CHANNELS = [
  {
    key: 'sistema',
    value: 'Sistema',
    readOnly: true,
  },
  {
    key: 'client',
    value: 'Comunicación con Cliente',
    readOnly: false,
  },
  {
    key: 'instalador',
    value: 'Comunicación con Instalador',
    readOnly: false,
  },
  {
    key: 'gremio',
    value: 'Comunicación con Asociación',
    readOnly: false,
  },
]

export const COMMS_CHANNELS_GESTOR_GC = [
  {
    key: 'sistema',
    value: 'Sistema',
    readOnly: true,
  },
  {
    key: 'client',
    value: 'Comunicación con Cliente',
    readOnly: false,
  },
  {
    key: 'instalador',
    value: 'Comunicación con Instalador',
    readOnly: false,
  },
  {
    key: 'gremio',
    value: 'Comunicación con Asociación',
    readOnly: false,
  },
]

export const COMMS_CALL_CENTER_CHANNELS = [
  {
    key: 'sistema',
    value: 'Sistema',
    readOnly: true,
  },
  /*   {
    key: 'client',
    value: 'Comunicación con Cliente',
    readOnly: true,
  }, */
  {
    key: 'instalador',
    value: 'Comunicación con Instalador',
    readOnly: false,
  },
  {
    key: 'gremio',
    value: 'Comunicación con Asociación',
    readOnly: false,
  },
  { key: 'gestor', value: 'Comunicación con Gestor', readOnly: false },
  { key: 'e_commerce', value: 'Comunicación con E-commerce', readOnly: false },
  {
    key: 'instalador-gestor',
    value: 'Ver comunicación entre Instalador y Gestor',
    receiver: 'instalador',
    sender: 'gestor',
    readOnly: true,
  },
  {
    key: 'gremio-gestor',
    value: 'Ver comunicación entre Asociación y Gestor',
    receiver: 'gremio',
    sender: 'gestor',
    readOnly: true,
  },
  {
    key: 'ecommerce-instalador',
    value: 'Ver comunicación entre E-commerce e Instalador',
    receiver: 'e_commerce',
    sender: 'instalador',
    readOnly: true,
  },
  {
    key: 'ecommerce-gremio',
    value: 'Ver comunicación entre E-commerce y Asociacion',
    receiver: 'e_commerce',
    sender: 'gremio',
    readOnly: true,
  },
  {
    key: 'cliente-gestor',
    value: 'Ver comunicación entre Gestor y Cliente',
    receiver: 'gestor',
    sender: 'client',
    readOnly: true,
  },
]

export const COMMS_CHANNELS_ECOMMERCE = [
  {
    key: 'sistema',
    value: 'Sistema',
    readOnly: true,
  },
  {
    key: 'instalador',
    value: 'Comunicación con Instalador',
    readOnly: false,
  },
  {
    key: 'gremio',
    value: 'Comunicación con Asociación',
    readOnly: false,
  },
  {
    key: 'call_center',
    value: 'Comunicación con Plataforma',
    receiver: 'call_center',
    readOnly: false,
  },
]
