import { APPLICATION_STATES } from './application-states.constants'

export const CANT_CREATE_CERTIFICATE_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.REJECTED_SURVEY.KEY]: true,
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
}

export const CANT_PASS_TO_GUILD_STATES = {
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
}

export const CAN_SEE_CERTIFICATE = {
  gestor: true,
  delegado: true,
  gestor_gc: true,
}

export const CANT_UPLOAD_DOCS_STATES = {
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
}

export const CANCEL_PERMITTED_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: APPLICATION_STATES.CREATED.DESCRIPTION,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: APPLICATION_STATES.NOT_ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.ASSIGNED.KEY]: APPLICATION_STATES.ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: APPLICATION_STATES.BUDGET_SENDED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: APPLICATION_STATES.BUDGET_ACCEPTED.DESCRIPTION,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: APPLICATION_STATES.PAYMENT_VALIDATION.DESCRIPTION,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: APPLICATION_STATES.ORDER_PENDING.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: APPLICATION_STATES.BUDGET_PAID.DESCRIPTION,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]:
    APPLICATION_STATES.CERTIFICATE_ATTACHED.DESCRIPTION,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]:
    APPLICATION_STATES.CERTIFICATE_REJECTED.DESCRIPTION,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]:
    APPLICATION_STATES.CERTIFICATE_PROCESSED.DESCRIPTION,
  [APPLICATION_STATES.REVIEW.KEY]: APPLICATION_STATES.REVIEW.DESCRIPTION,
}

export const APP_FINISHED_STATES = {
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
  [APPLICATION_STATES.REJECTED_SURVEY.KEY]: true,
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
}
