import { createTheme, responsiveFontSizes } from '@material-ui/core'

import { MATERIAL_COMPONENTS_OVERRIDES } from './overrides'
import { APP_THEME_COLORS } from './colors'

// Default theme: https://material-ui.com/customization/default-theme/
export let theme = responsiveFontSizes(
  createTheme({
    overrides: {MATERIAL_COMPONENTS_OVERRIDES,
      MUIRichTextEditor: {
        root: {
          borderRadius: "4px",
          border: "1px solid #ebebeb",
          "&:hover": {
                      border: "1px solid #ebebeb",
          },
          marginTop: "10px",
        },
        container: {
          display: "flex",
          flexDirection: "column"
        },
        editor: {
          padding: "20px",
          maxHeight: "200px",
          overflow: "auto",
          minHeight: "50px",
        },
        toolbar: {
          borderBottom: "1px solid #ebebeb",
        },
        placeHolder: {
          paddingLeft: 20,
          top: "49px",
          width: "calc(100% - 44px)",
          height: "50px",
          padding: "20px",
          margin: 0
        },
      }
    },
    palette: {
      primary: {
        main: APP_THEME_COLORS[30],
        contrastText: APP_THEME_COLORS[10],
      },
      secondary: {
        main: APP_THEME_COLORS[40],
        contrastText: APP_THEME_COLORS[20],
      },
    },
    typography: {
      fontFamily: 'FSEmeric-Regular',
      h4: {
        fontFamily: 'FSEmeric-Medium',
      },
      h5: {
        fontFamily: 'FSEmeric-Medium',
      },
      h6: {
        fontFamily: 'FSEmeric-Medium',
        lineHeight: 1,
      },
      subtitle1: {
        fontFamily: 'FSEmeric-Medium',
        lineHeight: 1.25,
      },
      subtitle2: {
        fontFamily: 'FSEmeric-Medium',
      },
      body1: {
        lineHeight: 1.25,
      },
      caption: {
        lineHeight: 1.2,
      },
    },
  })
)
