import { PO_REQ_TABLE_COLUMNS } from '../components'
import { PO_REQ_EXPORT_COLUMNS } from '../components'

export const poRequestsTableDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    const formattedText = {}
    PO_REQ_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.multiple && Array.isArray(item[column.field]) && item[column.field].length > 0) {
        const itemValues = item[column.field].map(({ nombre }) => nombre)
        formattedText[column.field] = itemValues.join(', ')
      }
    })
    return {
      ...item,
      ...formattedDates,
      ...formattedText,
    }
  })
}

export const poRequestsExportDataPreparation = ({ data, combos, intl }) => {
  const formattedData = data.map((item) => {
    const formattedText = {}
    PO_REQ_EXPORT_COLUMNS.forEach((column) => {
      if (column.exportType === 'date' && item[column.field]) {
        const date = new Date(item[column.field])
        const formatedDate = intl.formatDate(date)
        formattedText[column.field] = formatedDate
      } else if (column.field === 'estado') {
        const estadoValue = combos.estados_peticion_op.data?.find(
          (option) => option.key === item[column.field]
        )
        formattedText[column.field] = estadoValue?.value || item[column.field]
      }
    })
    return {
      ...item,
      ...formattedText,
    }
  })
  return formattedData
}
