import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { TextInput, ThemeButton } from '../../../../ui'

export const AddSAPCodeDialog = ({ close, addSAPCode }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({ cod_sap: '' })

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmit = () => {
    setLoading(true)
    addSAPCode(formState)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.poRequests.detail.addSAPCode.dialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {formatMessage({ id: 'pages.poRequests.detail.addSAPCode.dialog.description' })}
        </Typography>
        <Box pt={2}>
          <TextInput
            required={true}
            name="cod_sap"
            value={formState.cod_sap}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="space-around" pt={3} flexWrap="wrap">
          <ThemeButton onClick={handleSubmit} color="primary" loading={loading} disabled={loading}>
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
          <ThemeButton onClick={close} color="inherit" disabled={loading}>
            {formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
      </DialogContent>
    </Box>
  )
}
