import { Box, Container, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'

import { useCombos } from '../../../features/combos'
import { GuildsHeader, GuildsTable, useGuildCalls } from '../../../features/guilds'
import { useURLLoader } from '../../../features/loader/hooks/loader.hook'
import { guildStyles } from './guilds.styles'

export const GuildsPage = withStyles(guildStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getGuilds } = useGuildCalls()
  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'guilds',
    call: getGuilds,
  })
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.guilds.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <GuildsHeader />
      </Box>
      <Box>
        <GuildsTable />
      </Box>
    </Container>
  )
})
