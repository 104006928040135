import React from 'react'
import { withStyles, Box, Grid, Typography } from '@material-ui/core'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { gridFormStyles } from './grid-form.styles'

export const GridForm = withStyles(gridFormStyles)(
  ({ classes, grid, inputs, handleValidateChange }) => {
    return grid.map((gridRow, i) => (
      <Box key={i} className={gridRow.panel ? classes.panelRow : classes.row}>
        {gridRow.title ? (
          <Typography variant="h6" color="primary" gutterBottom>
            {gridRow.title}
          </Typography>
        ) : null}
        <Grid container spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
          {gridRow.fields.map((gridItem, i) => {
            const { component, ...restItemProps } = inputs[gridItem.ref]
            const InputComponent = component
            return restItemProps.hidden ? null : (
              <Grid {...{ ...gridItem.conf, key: i }}>
                <Box display="flex">
                  <Box display={'block'}>
                    <InputComponent {...restItemProps} />
                  </Box>
                  {restItemProps.hasChanges ? (
                    <Box ml={'0.5em'}>
                      <NotificationsActiveIcon
                        color="secondary"
                        style={{ fontSize: 18, cursor: 'pointer' }}
                        onClick={() =>
                          handleValidateChange(restItemProps.name, restItemProps.title)
                        }
                      />
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    ))
  }
)
