export const COMBOS_MOCK = {
  horarios_apertura: {
    data: [
      { key: '8-10h', value: '8-10h' },
      { key: '10-12h', value: '10-12h' },
      { key: '12-14h', value: '12-14h' },
      { key: '14-16h', value: '14-16h' },
      { key: '16-18h', value: '16-18h' },
    ],
  },
  //TODO: Encontrar una solución creativa a este problema
  motivos_anulacion_workaround: {
    isLoading: false,
    data: [
      {
        key: '01',
        value: 'El cliente no quiere gas',
      },
      {
        key: '02',
        value: 'Error administrativo',
      },
      {
        key: '03',
        value: 'El cliente no acepta presupuesto',
      },
      {
        key: '04',
        value: 'Cliente no quiere por retraso en obtención de permisos',
      },
      {
        key: '05',
        value: 'No avance de estado de la solicitud',
      },
      {
        key: '06',
        value: 'Finalizada conexión de acometida sin avance',
      },
      {
        key: '07',
        value: 'Operación no resuelta',
      },
      {
        key: '08',
        value: 'Cliente solo estaba buscando información',
      },
      {
        key: '09',
        value: 'Cliente no entiende el proceso',
      },
      {
        key: '10',
        value: 'Cliente no quiere por razones económicas',
      },
      {
        key: '11',
        value: 'No era lo que estaba buscando el cliente',
      },
      {
        key: '12',
        value: 'Error administrativo',
      },
      {
        key: '13',
        value: 'Cliente no quiere por retraso en obtención de permisos',
      },
      {
        key: '14',
        value: 'Otra razón',
      },
    ],
  },
  binaryValues: {
    data: [
      { key: '00', value: 'No' },
      { key: '01', value: 'Sí' },
    ],
    isLoading: false,
  },
  ip_categories: {
    data: [
      { key: 'A', value: 'A' },
      { key: 'B', value: 'B' },
      { key: 'C', value: 'C' },
    ],
    isLoading: false,
  },
  estados_iban: {
    data: [
      { key: '10', value: 'Pte. informar' },
      { key: '15', value: 'Pte. validar' },
      { key: '20', value: 'Validado' },
      { key: '01', value: 'Rechazado' },
    ],
    isLoading: false,
  },
  caminos_texto: {
    data: [
      { key: '00', value: 'No permitido' },
      { key: '01', value: 'Acometida' },
      { key: '02', value: 'Instalación Interior' },
      { key: '03', value: 'Instalación Interior' },
      { key: '04', value: 'Trámite certificado' },
    ],
    isLoading: false,
  },
  tipo_instalacion: {
    data: [
      {
        key: 'IRI',
        value: 'IRI',
      },
      {
        key: 'IRC',
        value: 'IRC',
      },
      {
        key: 'IND',
        value: 'IND',
      },
    ],
    isLoading: false,
  },
  une: {
    data: [
      {
        key: 'UNE 60670 - 2014',
        value: 'UNE 60670 - 2014',
      },
      {
        key: 'UNE 60620 - 2005',
        value: 'UNE 60620 - 2005',
      },
    ],
    isLoading: false,
  },
  resultado_inspeccion: {
    data: [
      {
        key: 'Correcto',
        value: 'Correcto',
      },
      {
        key: 'Con anomalías secundarias',
        value: 'Con anomalías secundarias',
      },
      {
        key: 'Con anomalías principales',
        value: 'Con anomalías principales',
      },
      {
        key: 'Con anomalías secundarias y principales',
        value: 'Con anomalías secundarias y principales',
      },
    ],
    isLoading: false,
  },
  situacion_servicio: {
    data: [
      {
        key: 'Sin servicio Parcial',
        value: 'Sin servicio Parcial',
      },
      {
        key: 'Sin servicio Total',
        value: 'Sin servicio Total',
      },
    ],
    isLoading: false,
  },
  situacion_suministro: {
    data: [
      {
        key: 'En servicio',
        value: 'En servicio',
      },
      {
        key: 'Corte parcial',
        value: 'Corte parcial',
      },
      {
        key: 'Corte total',
        value: 'Corte total',
      },
    ],
    isLoading: false,
  },
  estados_inspeccion: {
    data: [
      {
        key: '27',
        value: 'Rechazado por gestor',
      },
      {
        key: '20',
        value: 'Rechazado por fecha de inspección',
      },
      {
        key: '23',
        value: 'Rechazado por criterio de aceptación',
      },
      {
        key: '26',
        value: 'Rechazado por no superar las validaciones',
      },
      {
        key: '24',
        value: 'Pendiente validación automática',
      },
      {
        key: '25',
        value: 'Pendiente Validar',
      },
      {
        key: '40',
        value: 'Validado',
      },
      {
        key: '41',
        value: 'Validado y cargado en Zeus',
      },
      {
        key: '42',
        value: 'Validado, envío fallido',
      },
      {
        key: '15',
        value: 'No conformidad',
      },
    ],
    isLoading: false,
  },
  estados_jca: {
    data: [
      {
        key: '30',
        value: 'Pte validar',
      },
      {
        key: '40',
        value: 'Validado',
      },
      {
        key: '41',
        value: 'Validado y cargado en Zeus',
      },
      {
        key: '42',
        value: 'Validado, envío fallido',
      },
      {
        key: '25',
        value: 'Rechazado',
      },
      {
        key: '15',
        value: 'No conformidad',
      },
      {
        key: '39',
        value: 'No conformidad cerrada',
      },
    ],
    isLoading: false,
  },
  roles_instalador: {
    data: [
      {
        key: 'instalador',
        value: 'Visualización Captación',
      },
      {
        key: 'administrador',
        value: 'Gestión Captación',
      },
      {
        key: 'administrador_grabador',
        value: 'Gestión Inspecciones',
      },
      {
        key: 'admin_captador_grabador',
        value: 'Gestión Captación e Inspecciones',
      },
    ],
    isLoading: false,
  },
  resultado_no_conformidad: {
    data: [
      { key: '1', value: 'Validado' },
      { key: '2', value: 'Rechazado' },
      { key: '3', value: 'Devuelto a EEII' },
    ],
    isLoading: false,
  },
  estados_peticion_op: {
    data: [
      { key: '10', value: 'Creada' },
      { key: '20', value: 'Pte. código SAP' },
      { key: '30', value: 'Pte. validar documentación' },
      { key: '40', value: 'Pte. registro Zeus' },
      { key: '50', value: 'Pte. adhesión Zeus' },
      { key: '60', value: 'Tramitada' },
      { key: '00', value: 'Rechazada' },
      { key: '01', value: 'Caducada' },
      { key: '02', value: 'Anulada' },
    ],
  },
  estados_inspeccion_hist: {
    data: [
      { key: 'INTERVENIDO', value: 'INTERVENIDO' },
      { key: 'ENVIADO_SAP', value: 'ENVIADO_SAP' },
      { key: 'PAGADO', value: 'PAGADO' },
      { key: 'ERROR_CARGA_SAP', value: 'ERROR_CARGA_SAP' },
      { key: 'RECHAZADO', value: 'RECHAZADO' },
      { key: 'VALIDADO', value: 'VALIDADO' },
    ],
    isLoading: false,
  },
  estados_jca_hist: {
    data: [
      { key: 'VALIDADO', value: 'VALIDADO' },
      { key: 'RECHAZADO', value: 'RECHAZADO' },
    ],
    isLoading: false,
  },
  tipo_perfil: {
    data: [
      { key: 'administrador', value: 'administrador' },
      { key: 'instalador', value: 'instalador' },
      { key: 'gremio', value: 'gremio' },
    ],
    isLoading: false,
  },
  tipo_dias: {
    data: [
      { key: '1', value: '1 día' },
      { key: '2', value: '2 días' },
      { key: '+2', value: 'Más de 2 días' },
    ],
  },
}
