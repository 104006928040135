//In order to avoid table ui malfunction one should make sure there's at least 1200
export const APPS_TABLE_COLUMNS = [
  {
    field: 'alerta',
    width: 80,
    align: 'right',
    sorting: true,
  },
  { field: 'solicitud_id', align: 'center', width: 180 },
  {
    field: 'responsable',
    comboId: 'gestores',
    align: 'left',
    width: 140,
    exportType: 'combo',
    sorting: false,
  },
  {
    field: 'estado',
    align: 'left',
    width: 180,
    exportType: 'combo',
    comboId: 'estados_sspp',
  },
  {
    field: 'tipologia',
    align: 'left',
    width: 250,
  },
  { field: 'documentos', align: 'left', width: 250 },
  {
    field: 'dg_ts_insert',
    align: 'center',
    width: 130,
    type: 'date',
    exportType: 'date',
  },
  {
    field: 'dg_ts_update',
    align: 'left',
    width: 130,
    type: 'date',
    exportType: 'date',
  },
  {
    field: 'fecha_finalizacion',
    align: 'left',
    width: 130,
    type: 'date',
    exportType: 'date',
  },
  {
    field: 'mercado',
    align: 'left',
    width: 190,
    exportType: 'combo',
    comboId: 'tipo_mercado',
  },
  { field: 'distribuidora', align: 'left', width: 200 },
  { field: 'nif_cliente', align: 'left', width: 200 },
  { field: 'cod_codigo_postal', align: 'left', width: 75 },
  { field: 'direccion', align: 'center', width: 250, sorting: false },
  { field: 'numero' },
  { field: 'anexo' },
  { field: 'escalera' },
  { field: 'puerta' },
  { field: 'municipio', align: 'left', width: 200 },
  {
    field: 'provincia',
    align: 'left',
    width: 140,
    exportType: 'combo',
    comboId: 'provincias_by_cp',
  },
  { field: 'cups', align: 'left', width: 200 },
  {
    field: 'observaciones',
    align: 'left',
    width: 350,
    mytype: 'large_text',
    sorting: false,
  },
]
export const EXTRA_COLUMNS = [
  {
    field: 'fecha_tratamiento_sspp',
  },
  {
    field: 'fecha_modificacion_peticionario',
  },
  {
    field: 'fecha_ultimo_tratamiento_sspp',
  },
  {
    field: 'ultimo_sspp_id',
    exportType: 'combo',
    comboId: 'gestores',
  },
  {
    field: 'antiguedad',
  },
  {
    field: 'empresa',
  },
  {
    field: 'nif_empresa',
  },
  {
    field: 'origen_solicitud',
    exportType: 'combo',
    comboId: 'origen_solicitud',
  },
  {
    field: 'importe_con_iva',
  },
  {
    field: 'importe_sin_iva',
  },
  {
    field: 'tarifa_aplicada',
  },
  {
    field: 'distancia_real',
  },
]
export const APPS_TABLE_ACTIONS = [
  // {
  //   field: 'view',
  //   width: 75,
  //   align: 'center',
  //   sorting: false,
  // },
]
