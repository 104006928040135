export const CAMPAIGNS_FILTERS = [
  {
    name: 'fechainicio',
    filterType: 'date',
    maxDateField: 'fechafin',
  },
  {
    name: 'fechafin',
    filterType: 'date',
    minDateField: 'fechainicio',
  },
  {
    name: 'ccaa',
    label: 'CCAA',
    values: [
      {
        key: '1',
        value: 'Andaluc\u00eda',
      },
      {
        key: '13',
        value: 'Madrid',
      },
      {
        key: '2',
        value: 'Aragon',
      },
      {
        key: '8',
        value: 'Castilla la Mancha',
      },
      {
        key: '7',
        value: 'Castilla y Leon',
      },
      {
        key: '9',
        value: 'Catalunya',
      },
      {
        key: '12',
        value: 'Galicia',
      },
      {
        key: '15',
        value: 'Navarra',
      },
      {
        key: '17',
        value: 'La Rioja',
      },
      {
        key: '10',
        value: 'Comunidad Valenciana',
      },
      {
        key: '4',
        value: 'Baleares',
      },
    ],
    size: 'small',
    multiple: false,
  },
  {
    name: 'tipo_mercado',
    comboId: 'tipo_mercado_campañas',
    size: 'small',
    multiple: true,
  },
  {
    name: 'activa',
    filterType: 'switch',
    cleanSelf: false,
  },
]
