import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  Grid,
  Typography,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { useSelector } from 'react-redux'

import { useConsultContext } from '../../contexts'
import { TextInput, ThemeButton, useModalContext } from '../../../../ui'
import { ADDRESS_FIELDS_CONFIG_NN } from '../../constants'
import { ProfileChoiceDialog } from '../../../applications/components/dialogs'
import { useTokenGeneratorCalls } from '../../../applications/hooks/token-generator-calls.hook'
import { LINK_CREATE_APP } from '../../../applications/constants'

export const NotNormalizedForm = () => {
  const intl = useIntl()
  const {
    addressForm,
    createApplication,
    changeCustomerForm,
    changeNotNormalizedAddressForm,
    selectedHome,
    reset,
    nextStep,
  } = useConsultContext()
  const combos = useSelector((state) => state.combos)
  const [selectedUso, setSelectedUso] = useState(undefined)
  const [usoError, setUsoError] = useState(false)
  const user = useSelector((state) => state.global)
  const { generateOneUseLink } = useTokenGeneratorCalls()
  const {
    actions: { open },
  } = useModalContext()

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (!user.role === 'gestor_gc') {
      createApplication().then((id) => {
        handleRedirectionToWordpress(id)
      })
    } else {
      changeCustomerForm({ name: 'isNN', value: true })
      nextStep({ replace: true })
    }
  }
  const handleRedirectionToWordpress = (id) => {
    if (user.role === 'selectra') {
      open({
        Component: ProfileChoiceDialog,
        data: {
          options: combos['tipo_solicitud']?.data,
          action: (profileId) => redirectToWordpress(id, profileId),
        },
      })
    } else if (user.role === 'gss_vt') {
      redirectToWordpress(id, 'GSS_VT')
    } else if (user.role === 'accom') {
      redirectToWordpress(id, 'accom')
    } else {
      redirectToWordpress(id, user.role === 'call_center' ? 'Call_center' : 'Gestor')
    }
  }

  const redirectToWordpress = (appId, profileId = false) => {
    generateOneUseLink(user.userId, profileId, appId, {
      form: selectedHome,
      context: addressForm,
    }).then(({ data }) => {
      window.open(LINK_CREATE_APP(data.message))
      reset()
    })
  }
  const handlePrevious = () => {
    reset()
  }
  const inputProps = {
    formState: selectedHome,
    filterHomeValues: [],
    onChange: (e) => {
      changeNotNormalizedAddressForm(e.target)
    },
  }
  const handleSelectUso = (e) => {
    const target = e.target
    setSelectedUso(target.value)
    setUsoError(false)
    changeCustomerForm({ name: 'uso', value: target.value })
  }
  const Uso = () => {
    const usosData = combos['uso_punto_suministro'] ? combos['uso_punto_suministro'].data : []
    return (
      <>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" display="inline" style={{ marginRight: 8 }}>
            {`${intl.formatMessage({ id: 'pages.consult.usoResultSubtitle' })}: `}
          </Typography>
          <FormControl component="fieldset" onChange={handleSelectUso}>
            <RadioGroup row value={selectedUso}>
              {usosData.map((uso) => {
                const label = uso.value
                return (
                  <FormControlLabel
                    key={uso.key}
                    value={uso.key}
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {label}
                      </Typography>
                    }
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <FormHelperText id="my-helper-text" error={usoError}>
          {usoError ? intl.formatMessage({ id: 'pages.consult.uso.error.message' }) : ''}
        </FormHelperText>
      </>
    )
  }

  return (
    <>
      <Box>
        <Typography variant="h4" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.notNormalized.title.1' })}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.notNormalized.addressSubtitle' })}
        </Typography>
      </Box>
      <Uso />
      <Box maxWidth={600} mt={3}>
        <form onSubmit={handleSubmitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).CP_MUNICIPIO,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).DIRECCION,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).NUMERO,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).BLOQUE,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).ESCALERA,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).PISO,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG_NN(inputProps).PUERTA,
                }}
              />
            </Grid>
          </Grid>
          <Box mt={3} display="flex" justifyContent="space-around">
            <ThemeButton type="button" variant="outlined" labelM="md" onClick={handlePrevious}>
              {intl.formatMessage({ id: 'pages.consult.resultsButtons.goBack' })}
            </ThemeButton>
            <ThemeButton type="submit" color="primary" labelM="md">
              {intl.formatMessage({ id: 'pages.notNormalized.addressSubmitButton' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default NotNormalizedForm
