export const PO_REQ_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_peticion_op',
    multiple: true,
  },
  {
    name: 'fecha_peticion_desde',
    filterType: 'date',
    maxDateField: 'fecha_peticion_hasta',
  },
  {
    name: 'fecha_peticion_hasta',
    filterType: 'date',
    minDateField: 'fecha_peticion_desde',
  },
]
