import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Grid, Box, Typography } from '@material-ui/core'

import { useConsultContext } from '../../contexts'
import { ThemeButton, AutocompleteInput, useModalContext } from '../../../../ui'
import { ADDRESS_FIELDS_CONFIG } from '../../constants'
import { WarningMssgDialog } from './warning-mssg.dialog'
import { ProfileChoiceDialog } from '../../../applications/components/dialogs'
import { useTokenGeneratorCalls } from '../../../applications/hooks/token-generator-calls.hook'
import { LINK_CREATE_APP } from '../../../applications/constants'
import { RequestGasPNotNormalizedComponent } from '../request-gas_p-not-normalized/request-gas_p-not-normalized.component'
import { CreateApplicationDialog } from '../../dialogs/create-application-dialog'

export const ConsultAddressForm = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const user = useSelector((state) => state.global)
  const { generateOneUseLink } = useTokenGeneratorCalls()
  const {
    actions: { open },
  } = useModalContext()
  const {
    isLoading,
    reset,
    addressForm,
    changeAddressForm,
    getTownValues,
    changeToNotNormalized,
    townValues,
    getAddressValues,
    addressValues,
    numberValues,
    homeValues,
    getConsult,
    changeCustomerForm,
  } = useConsultContext()
  const installerId = useSelector((state) => state.global.user_id)

  //Restriction: don't let the installer proceed in non-nedgia areas
  useEffect(() => {
    if (addressForm.cp_municipio?.zona_nedgia === false) {
      open({
        Component: WarningMssgDialog,
        data: { mssg: intl.formatMessage({ id: 'pages.consult.dialogs.mssg.1' }) },
        type: 'responsive',
      })
      reset()
    }
  }, [addressForm.cp_municipio]) // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToWordpress = useCallback(
    (profileId = false, payload = false) => {
      generateOneUseLink(
        user.userId,
        profileId,
        false,
        payload ? { ...addressForm, gas_p: payload } : addressForm
      ).then(({ data }) => {
        window.open(LINK_CREATE_APP(data.message))
        reset()
      })
    },
    [addressForm, user] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const handleRedirectionToWordpress = useCallback(
    (payload = false) => {
      if (user.role === 'selectra') {
        open({
          Component: ProfileChoiceDialog,
          data: {
            options: combos['tipo_solicitud']?.data,
            action: (profileId) => redirectToWordpress(profileId, payload),
          },
        })
      } else if (user.role === 'gss_vt') {
        redirectToWordpress('GSS_VT', payload)
      } else if (user.role === 'accom') {
        redirectToWordpress('accom', payload)
      } else {
        redirectToWordpress(user.role === 'call_center' ? 'Call_center' : 'Gestor', payload)
      }
    },
    [redirectToWordpress, combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleOpenRequestGasP = useCallback(
    (dataUpdate) => {
      open({
        Component: RequestGasPNotNormalizedComponent,
        data: {
          application: addressForm,
          handleRedirectionToWordpress,
          dataUpdate,
          role: user.role,
          changeToNotNormalized,
          changeCustomerForm,
        },
        type: 'responsive',
        maxWidth: 'sm',
      })
    },
    [addressForm, handleRedirectionToWordpress] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Transitions to non-normalized form. They happen whenever either
  // 1) a non-normalized choice is made,                         or
  // 2) all choices are made but there is still uncertainty.
  //In either case, the user controls whether the transition happens or not through a modal
  const handleUnnormalize = useCallback(
    (dataUpdate) => {
      open({
        Component: CreateApplicationDialog,
        data: {
          role: user.role,
          changeToNotNormalized,
          dataUpdate,
          handleRedirectionToWordpress,
          handleOpenRequestGasP,
        },
        type: 'responsive',
      })
    },
    [handleRedirectionToWordpress] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Upon selection of "other", suggest non-normalized form
  useEffect(() => {
    if (
      addressForm['vivienda']?.special === 'other' ||
      addressForm['numero']?.special === 'other'
    ) {
      handleUnnormalize({ numero: addressForm['numero']?.numero || '' })
    }
  }, [addressForm]) // eslint-disable-line react-hooks/exhaustive-deps

  //Subtmit button
  const handleSubmitForm = (e) => {
    e.preventDefault()
    const { estado, estado_cups } = addressForm['vivienda']
    if (estado === 'Ya tiene gas' || estado_cups === 'activo')
      open({
        Component: WarningMssgDialog,
        data: { mssg: intl.formatMessage({ id: 'pages.consult.dialogs.mssg.2' }) },
        type: 'responsive',
      })
    else getConsult(addressForm['vivienda'], installerId)
  }

  const inputProps = { formState: addressForm, onChange: changeAddressForm }

  return (
    <>
      <Box>
        <Typography variant="h4" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.title.1' })}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.addressSubtitle' })}
        </Typography>
      </Box>
      <Box mt={3}>
        <form onSubmit={handleSubmitForm}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).CP_MUNICIPIO,
                  values: townValues,
                  onType: getTownValues,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).DIRECCION,
                  values: addressValues,
                  onType: getAddressValues,
                  inputMinLength: 1,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).NUMERO,
                  values: [...numberValues, { special: 'other', numero: 'other' }],
                }}
              />
            </Grid>
            <Grid item xs={12} sm>
              <AutocompleteInput
                {...{
                  ...ADDRESS_FIELDS_CONFIG(inputProps).VIVIENDA,
                  values: [...homeValues, { special: 'other', vivienda: 'other' }],
                }}
              />
            </Grid>
          </Grid>
          <Box mt={4}>
            <ThemeButton
              type="submit"
              color="secondary"
              labelM="md"
              loading={isLoading}
              disabled={!addressForm['vivienda']?.cidi}
            >
              {intl.formatMessage({ id: 'pages.consult.addressSubmitButton' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </>
  )
}
