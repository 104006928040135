import React from 'react'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { useURLLoader } from "../../../features/loader/hooks"
import { StuckHeader } from '../../../features/stuck/components/header'
import { StuckTable } from '../../../features/stuck/components/table/stuck-table.component'
import { useStuckCalls } from '../../../features/stuck/hooks/stuck-calls.hook'
import { stuckStyles } from './stuck.styles'
import { useCombos } from "../../../features/combos"
import { STUCK_COMBOS_IDS } from '../constants'

export const StuckPage = withStyles(stuckStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getStuck } = useStuckCalls()
  useCombos(STUCK_COMBOS_IDS)

  useURLLoader({
    call: getStuck,
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'stuck',
  })

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.stuck.title' })}
        </Typography>
      </Box>
      <Box mb={3}>
        <StuckHeader />
      </Box>
      <Box>
        <StuckTable />
      </Box>
    </Container>
  )
})

export default StuckPage
