import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import { parse, stringify } from 'qs'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined'
import AssignmentLate from '@material-ui/icons/AssignmentLate'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import { TableActions } from '../table-actions'
import { TableUrlPagination } from '../../../../ui'

import { appsTableStyles } from './apps-table.styles'
import { setSelectedApps, resetSelectedApps } from '../../slices'
import { applicationDataPreparation } from '../../logics'
import { useApplicationUserPermitsContext } from '../../../../pages/applications/contexts'
import { useApplicationsCalls } from '../../hooks'
import { DEFAULT_QUERY } from '../../constants'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const AppsTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    data,
    search,
    pagination: { total_solicitudes: totalEntries },
    isLoading: loading,
    selectedRows,
  } = useSelector((state) => state.applications)
  const { columns, tableOptions, goesToDetail } = useApplicationUserPermitsContext()
  const combos = useSelector((state) => state.combos)
  const { getApplications } = useApplicationsCalls()

  //Selections: unselect on first render, and manage with handler:
  useEffect(() => {
    dispatch(resetSelectedApps())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback(
    (rows, row) => dispatch(setSelectedApps(rows)),
    [setSelectedApps, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    if (location.state?.cleanup) {
      dispatch(resetSelectedApps())
    }
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getApplications(searchParams)
    } else {
      history.push(`/applications?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoToDetail = useCallback(
    (e, row) => {
      const props = {
        pathname: `/applications/${row['id']}`,
        state: { prevSearch: history.location.search },
      }
      history.push(props)
    },
    [history] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Formatted columns:
  const formattedColumns = useMemo(() => {
    return columns.map((column) => {
      if (column.field === 'pendiente_peticionario') {
        return {
          ...column,
          title: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <AssignmentOutlined style={{ fontSize: 18 }} />
            </span>
          ),
        }
      }
      if (column.field === 'actions')
        return {
          ...column,
          title: formatMessage({ id: 'global.actions' }),
        }
      if (column.field === 'comunicaciones_pendientes')
        return {
          ...column,
          title: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <NotificationsNoneIcon style={{ fontSize: 18 }} />
            </span>
          ),
        }
      if (column.field === 'valoraciones_pendientes')
        return {
          ...column,
          title: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <StarBorderIcon style={{ fontSize: 18 }} />
            </span>
          ),
        }
      return {
        ...column,
        title: formatMessage({ id: `pages.applications.table.columns.${column.field}.title` }),
        cellStyle: {
          ...appsTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
          maxWidth: column.width,
        },
        emptyValue: () => <span>--</span>,
        lookup:
          column.hasOwnProperty('comboId') && combos[column.comboId] && combos[column.comboId].data
            ? getLookup(combos[column.comboId].data)
            : null,
      }
    })
  }, [columns, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Assembling and adjusting data
  const processedData = useMemo(() => {
    const selectedIds = selectedRows.map((el) => el.id)
    return applicationDataPreparation(data, { intl }, columns).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, selectedRows, columns]) // eslint-disable-line react-hooks/exhaustive-deps

  //Table configuration
  const finalTableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      headerStyle: appsTableStyles.header,
      draggable: false,
      ...tableOptions,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getFormattedCell = (props) => {
    if (props.columnDef.field === 'actions') {
      return <TableActions {...props} />
    } else if (
      props.columnDef.field === 'comunicaciones_pendientes' &&
      props.rowData.comunicaciones_pendientes > 0
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NotificationsActiveIcon color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    } else if (
      props.columnDef.field === 'valoraciones_pendientes' &&
      props.rowData.valoraciones_pendientes
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <StarIcon color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    } else if (
      props.columnDef.field === 'pendiente_peticionario' &&
      props.rowData.pendiente_peticionario > 0
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AssignmentLate color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    }
  }

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const mTableCellProps =
          props.columnDef.field === 'comunicaciones_pendientes' ||
          props.columnDef.field === 'valoraciones_pendientes' ||
          props.columnDef.field === 'pendiente_peticionario'
            ? { ...props, value: null }
            : props

        return (
          <MTableCell {...mTableCellProps} style={{ padding: 0 }}>
            {getFormattedCell(props)}
          </MTableCell>
        )
      },
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={finalTableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onSelectionChange={handleSelectRows}
      onRowClick={goesToDetail ? handleGoToDetail : undefined}
    />
  )
}
