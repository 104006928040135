export const PO_REQUEST_STATES = {
  CREATED: '10',
  PENDING_SAP: '20',
  PENDING_DOCS_REVIEW: '30',
  PENDING_ZEUS_REGISTER: '40',
  PENDING_ZEUS_ADHESION: '50',
  PROCESSED: '60',
  REJECTED: '00',
  EXPIRED: '01',
  CANCELLED: '02',
}
