import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, useModalContext, ConfirmationDialog } from '../../../../ui'
import { usePORequests } from '../../hooks'
import { PO_REQUEST_STATES } from '../../constants'
import {
  AddSAPCodeDialog,
  AddsZeusRegisterCodeDialog,
  RejectRequestDialog,
  SendMessageDialog,
} from '../dialogs'
import { CancelRequestDialog, AddsZeusAdhesionCodeDialog } from '../dialogs'

export const POReqFlowBtns = () => {
  const { formatMessage: fm } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { poRequest, sendToSAP, addSAPCode, validateDocs, rollbackRequest } = usePORequests()
  const { addZeusRegisterCode, addZeusAdhesionCode, cancelRequest, rejectRequest, sendMessage } =
    usePORequests()

  const handleCancelRequest = () => {
    open({
      Component: CancelRequestDialog,
      data: { cancelRequest },
      maxWidth: 'sm',
    })
  }

  const handleRejectRequest = () => {
    open({
      Component: RejectRequestDialog,
      data: { rejectRequest },
      maxWidth: 'sm',
    })
  }

  const handleRollbackRequest = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: 'pages.poRequests.detail.rollbackRequest.dialog.title' }),
        text: fm({ id: 'pages.poRequests.detail.rollbackRequest.dialog.description' }),
        yesText: fm({ id: 'global.accept' }),
        noText: fm({ id: 'global.cancel' }),
        yesAction: rollbackRequest,
      },
    })
  }

  const handleSendMessage = () => {
    open({
      Component: SendMessageDialog,
      data: { sendMessage },
      maxWidth: 'sm',
    })
  }

  const handleSendToSAP = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: 'pages.poRequests.detail.sendToSAP.dialog.title' }),
        text: fm(
          { id: 'pages.poRequests.detail.sendToSAP.dialog.description' },
          { email: poRequest.email_f1 || '' }
        ),
        yesText: fm({ id: 'global.accept' }),
        noText: fm({ id: 'global.cancel' }),
        yesAction: sendToSAP,
      },
    })
  }

  const handleAddSAPCode = () => {
    open({
      Component: AddSAPCodeDialog,
      data: { addSAPCode },
      maxWidth: 'xs',
    })
  }

  const handleValidateDocs = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: 'pages.poRequests.detail.validateDocs.dialog.title' }),
        text: fm({ id: 'pages.poRequests.detail.validateDocs.dialog.description' }),
        yesText: fm({ id: 'global.accept' }),
        noText: fm({ id: 'global.cancel' }),
        yesAction: validateDocs,
      },
      maxWidth: 'sm',
    })
  }

  const handleAddZeusRegisterCode = () => {
    open({
      Component: AddsZeusRegisterCodeDialog,
      data: { addZeusRegisterCode },
      maxWidth: 'xs',
    })
  }

  const handleAddZeusAdhesionCode = () => {
    open({
      Component: AddsZeusAdhesionCodeDialog,
      data: { addZeusAdhesionCode },
      maxWidth: 'xs',
    })
  }

  return poRequest.estado === PO_REQUEST_STATES.CANCELLED ||
    poRequest.estado === PO_REQUEST_STATES.EXPIRED ? null : (
    <Box display="flex" justifyContent="space-between" width="100%" flexWrap="wrap">
      <Box display="flex">
        {poRequest.estado !== PO_REQUEST_STATES.CANCELLED ? (
          <ThemeButton style={{ marginRight: 14 }} color="default" onClick={handleCancelRequest}>
            {fm({ id: 'pages.poRequests.detail.cancelRequest.btn.label' })}
          </ThemeButton>
        ) : null}
        {poRequest.estado !== PO_REQUEST_STATES.PROCESSED &&
        poRequest.estado !== PO_REQUEST_STATES.REJECTED ? (
          <ThemeButton color="default" onClick={handleRejectRequest}>
            {fm({ id: 'pages.poRequests.detail.rejectRequest.btn.label' })}
          </ThemeButton>
        ) : null}
        {poRequest.estado === PO_REQUEST_STATES.REJECTED ? (
          <Box display={'flex'}>
            <Box mr={'14px'}>
              <ThemeButton color="secondary" onClick={handleRollbackRequest}>
                {fm({ id: 'pages.poRequests.detail.rollbackRequest.btn.label' })}
              </ThemeButton>
            </Box>
            <Box>
              <ThemeButton color="primary" onClick={handleSendMessage}>
                {fm({ id: 'pages.poRequests.detail.sendMessage.btn.label' })}
              </ThemeButton>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Box display="flex">
        {poRequest.estado === PO_REQUEST_STATES.CREATED ? (
          <ThemeButton onClick={handleSendToSAP}>
            {fm({ id: 'pages.poRequests.detail.sendToSAP.btn.label' })}
          </ThemeButton>
        ) : null}
        {poRequest.estado === PO_REQUEST_STATES.PENDING_SAP ? (
          <ThemeButton onClick={handleAddSAPCode}>
            {fm({ id: 'pages.poRequests.detail.addSAPCode.btn.label' })}
          </ThemeButton>
        ) : null}
        {poRequest.estado === PO_REQUEST_STATES.PENDING_DOCS_REVIEW ? (
          <ThemeButton onClick={handleValidateDocs}>
            {fm({ id: 'pages.poRequests.detail.validateDocs.btn.label' })}
          </ThemeButton>
        ) : null}
        {poRequest.estado === PO_REQUEST_STATES.PENDING_ZEUS_REGISTER ? (
          <ThemeButton onClick={handleAddZeusRegisterCode}>
            {fm({ id: 'pages.poRequests.detail.addZeusRegisterCode.btn.label' })}
          </ThemeButton>
        ) : null}
        {poRequest.estado === PO_REQUEST_STATES.PENDING_ZEUS_ADHESION ? (
          <ThemeButton style={{ marginLeft: 14 }} onClick={handleAddZeusAdhesionCode}>
            {fm({ id: 'pages.poRequests.detail.addZeusAdhesionCode.btn.label' })}
          </ThemeButton>
        ) : null}
      </Box>
    </Box>
  )
}
