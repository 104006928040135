import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { TextInput, PasswordInput, ThemeButton } from '../../../ui'
import { loginUser } from '../../../features/global'
import { AuthContext } from '../../../pages/auth'
import { useUserContext } from '../../../core/user'
import { PwdRecoveryMessageComponent } from '../../pwd-recovery'

export const LoginFormComponent = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { userDispatch } = useUserContext()
  const login = useContext(AuthContext)

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    login(formState)
      .then(({ data }) => {
        setLoading(false)
        dispatch(loginUser(data, userDispatch))
      })
      .catch(() => {
        setErrorMssg('Usuario o contraseña incorrecta')
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={4}>
        <TextInput
          required
          value={formState['username']}
          name="username"
          title={formatMessage({ id: 'pages.login.form.field.user.title' })}
          placeholder={formatMessage({ id: 'pages.login.form.field.user.placeholder' })}
          onChange={handleChangeForm}
        />
        <PasswordInput
          required
          value={formState['password']}
          name="password"
          title={formatMessage({ id: 'pages.login.form.field.password.title' })}
          placeholder={formatMessage({ id: 'pages.login.form.field.password.placeholder' })}
          onChange={handleChangeForm}
        />
        <PwdRecoveryMessageComponent />
      </Box>

      {errorMssg && (
        <Box mb={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <ThemeButton type="submit" fullWidth loading={loading}>
        {formatMessage({ id: 'pages.login.button.label' })}
      </ThemeButton>
    </form>
  )
}
