import { Box, Container, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'

import { useCombos } from '../../../features/combos'
import { PaymentsHeader, PaymentsTable, usePaymentCalls } from '../../../features/payments'
import { useURLLoader } from '../../../features/loader/hooks/loader.hook'
import { guildStyles } from './payment.styles'

export const PaymentsPage = withStyles(guildStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getPayments } = usePaymentCalls()
  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'payments',
    call: getPayments,
  })
  useCombos(['marca_tarjeta'], ['marca_tarjeta'])
  useCombos(['tipo_tarjeta'], ['tipo_tarjeta'])
  useCombos(['resultado_pago'], ['resultado_pago'])

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box mb={3} mt={1}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.payments.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <PaymentsHeader />
      </Box>
      <Box>
        <PaymentsTable />
      </Box>
    </Container>
  )
})
