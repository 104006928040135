export const encodeToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let result = ''
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      result = e.target.result.split(',')[1]
      resolve(result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
  })
}

const getCompleteFileType = (type) => {
  switch (type) {
    case 'pdf':
      return `application/${type}`
    case 'jpg':
    case 'jpeg':
    case 'png':
      return `image/${type}`
    case 'xls':
      return type
    default:
      return `application/${type}`
  }
}

export const downloadFile = (data, fileExtension, fileName) => {
  const base64Data = data.split("'")[1]
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = `data:${getCompleteFileType(fileExtension)};base64,${base64Data}`
  link.download = `${fileName}.${fileExtension}`
  // link.rel = 'noopener noreferrer'
  // document.body.appendChild(link)
  link.click()
}

export const getFormattedTime = () => {
  var today = new Date()
  var y = today.getFullYear()
  var m = today.getMonth() + 1 // JavaScript months are 0-based
  var d = today.getDate()
  var h = today.getHours()
  var mi = today.getMinutes()
  return d + '_' + m + '_' + y + '_' + h + '_' + mi
}

const getFormatedValue = (item, column, combos, intl) => {
  switch (column.exportType) {
    case 'date_iso': {
      return intl.formatDate(new Date(item[column.field]))
    }
    case 'date': {
      var dateParts = item[column.field].split('/')
      return intl.formatDate(new Date(dateParts[2], dateParts[1] - 1, dateParts[0]), {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    }
    case 'combo': {
      const comboOption =
        column.comboId &&
        combos[column.comboId] &&
        combos[column.comboId].data.find(({ key }) => key + '' === item[column.field] + '')
      return comboOption ? comboOption.value : item[column.field]
    }
    case 'currency': {
      let val_with_decimal = parseFloat(item[column.field]).toFixed(2)
      return val_with_decimal.toString().replace('.', ',')
    }
    default: {
      return item[column.field]
    }
  }
}

export const capitalizeStr = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (!word.length) {
        return ''
      }
      return word[0].toUpperCase() + word.substr(1)
    })
    .join(' ')
}

export const dataToXLS = (data, columns, combos, intl, from) => {
  const headerRow = columns.map((column) => {
    return intl.formatMessage({
      id: `pages.${from ? from : 'applications'}.table.columns.${column.field}.title`,
    })
  })
  const restRows = data.map((record) => {
    let rowArray = []
    columns.forEach((column) => {
      let value = ''
      if (record[column.field]) {
        value = getFormatedValue(record, column, combos, intl)
      } else {
        value = record[column.field]
      }
      rowArray.push(value)
    })
    return rowArray
  })
  return [headerRow, ...restRows]
}
