import { APPLICATION_STATES } from '../../../constants'

export const GRID_ITEM = {
  FULL: {
    item: true,
    xs: 12,
  },
  LARGE: {
    item: true,
    xs: 12,
    sm: 6,
  },
  SMALL: {
    item: true,
    xs: 6,
    sm: 4,
    md: 3,
  },
}

export const BUDGET_NOT_SENT_YET_STATES = {
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
}

export const CAN_UPDATE_ZEUS_CODE = {
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
}

export const ALLOWED_IF_L4 = {
  ...BUDGET_NOT_SENT_YET_STATES,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
}

export const ALLOWED_SEND_BUDGET_STATES = {
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
}

export const CANCELLED_STATES = {
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
}

export const APP_CLOSED_STATES = {
  ...CANCELLED_STATES,
  [APPLICATION_STATES.FINISHED.KEY]: true,
}

export const NOT_ALLOWED_CANCELLATION_STATES = {
  [APPLICATION_STATES.FINISHED.KEY]: true,
  [APPLICATION_STATES.REJECTED_SURVEY.KEY]: true,
  [APPLICATION_STATES.NORMALIZATION_PROCESSED.KEY]: true,
  [APPLICATION_STATES.REJECTED_NORMALIZATION.KEY]: true,
  [APPLICATION_STATES.NORMALIZATION.KEY]: true,
  [APPLICATION_STATES.CANCEL_PENDING.KEY]: true,
  [APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY]: true,
  ...CANCELLED_STATES,
}

export const HAS_PAID_STATES = {
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
}

export const CAN_CHANGE_STATE_ROLES = {
  gestor: true,
  delegado: true,
  gestor_gc: true,
}
