import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography } from '@material-ui/core'
import { TariffsTable } from '../table'
import { TariffsFilter } from '../filter'
import { tariffsDashboardStyles } from './tariffs-dashboard.styles'
import { useURLLoader } from '../../../loader/hooks'
import { useTariffsCalls } from '../../../tariffs/hooks'

export const TariffsDashboard = withStyles(tariffsDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getTariffs } = useTariffsCalls()
  useURLLoader({
    identifier: 'tariffs',
    defaultChoices: { _pagina: 1, _num_x_pagina: 5 },
    call: getTariffs,
    minRefreshMs: 1000 * 60,
  })
  return (
    <Box>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.tariffs.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <TariffsFilter />
        </Box>
        <Box>
          <TariffsTable />
        </Box>
      </Container>
    </Box>
  )
})
