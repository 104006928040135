import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { feedback } from '../../../core/feedback'
import { useCombos } from '../../combos'
import { PO_REQ_ACTIONS_TYPES } from '../reducers'
import { poRequestsExportDataPreparation } from '../logics'
import { usePORequestsCalls } from './po-requests-calls.hook'

export const usePORequestsActions = (state, dispatch) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const history = useHistory()
  const {
    getPORequestsCall,
    getPORequestCall,
    putRequestCall,
    putValidateChangedFieldsCall,
    postSendMessageCall,
  } = usePORequestsCalls()
  const combos = useCombos([])
  const { poRequest, search } = state

  const redirectToTable = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/po-requests' + prevSearch)
  }

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: PO_REQ_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = (id) => {
    const props = {
      pathname: `/po-requests/${id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: PO_REQ_ACTIONS_TYPES.SET_LOADING,
      })
      getPORequestsCall(config)
        .then(({ data }) => {
          dispatch({
            type: PO_REQ_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['peticiones_campanas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_registros: data['total_peticiones'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPORequest = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: PO_REQ_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getPORequestCall(id)
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getExportPreparedData = () => {
    return new Promise((resolve, reject) => {
      getPORequestsCall({ params: { ...search, _num_x_pagina: 0 }, timeout: 0 })
        .then(({ data }) => {
          return resolve(
            poRequestsExportDataPreparation({ data: data.peticiones_campanas, combos, intl })
          )
        })
        .catch((err) => {
          feedback('error', fm({ id: 'global.errorDownload' }))
          return reject(err)
        })
    })
  }

  const cancelRequest = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { ...payload, estado: '02' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rejectRequest = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { ...payload, estado: '00' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rollbackRequest = useCallback(
    () =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { estado: poRequest.estado_anterior })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendToSAP = useCallback(
    () =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { estado: '20' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const addSAPCode = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { ...payload, estado: '30' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validateDocs = useCallback(
    () =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { estado: '40' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const addZeusRegisterCode = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { ...payload, estado: '50' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const addZeusAdhesionCode = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        putRequestCall(poRequest.id, { ...payload, estado: '60' })
          .then(({ data }) => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
              payload: data,
            })
            resolve(data)
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validateChangedFields = useCallback(
    (id, requestId) =>
      new Promise((resolve, reject) => {
        putValidateChangedFieldsCall(id).then(() => {
          getPORequestCall(requestId)
            .then(({ data }) => {
              dispatch({
                type: PO_REQ_ACTIONS_TYPES.SET_OP_REQUEST,
                payload: data,
              })
            })
            .then(() => resolve())
            .catch(() => {
              dispatch({
                type: PO_REQ_ACTIONS_TYPES.SET_DETAIL_FAILURE,
              })
              return reject('Error')
            })
        })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendMessage = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        postSendMessageCall(poRequest.id, { ...payload })
          .then(() => {
            resolve()
          })
          .catch(() => {
            dispatch({
              type: PO_REQ_ACTIONS_TYPES.SET_FAILURE,
            })
            reject()
          })
      }),
    [poRequest, dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    redirectToTable,
    getTableData,
    getPORequest,
    setSelectedTableRows,
    onClickTableRow,
    getExportPreparedData,
    cancelRequest,
    rejectRequest,
    rollbackRequest,
    sendToSAP,
    addSAPCode,
    validateDocs,
    addZeusRegisterCode,
    addZeusAdhesionCode,
    validateChangedFields,
    sendMessage,
    ...state,
  }
}
