import { Box, Container, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'

import { useCombos } from '../../../features/combos'
import { GuildsIP3Header, GuildsIP3Table, useGuildsIP3Calls } from '../../../features/guilds-ip3'
import { useURLLoader } from '../../../features/loader/hooks/loader.hook'
import { guildsIP3Styles } from './guilds-ip3.styles'

export const GuildsIP3Page = withStyles(guildsIP3Styles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getGuilds } = useGuildsIP3Calls()
  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'guildIP3',
    call: getGuilds,
  })
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])

  return (
    <Container className={classes.container} maxWidth="lg">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.guildsIP3.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <GuildsIP3Header />
      </Box>
      <Box>
        <GuildsIP3Table />
      </Box>
    </Container>
  )
})
