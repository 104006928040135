import React, { useCallback, useMemo, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { parse, stringify } from 'qs'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { TableUrlPagination } from '../../../../ui'
import { useCombos } from '../../../combos'
import { usePORequests } from '../../hooks'
import { poRequestsTableDataPreparation } from '../../logics'
import { PO_REQ_TABLE_COLUMNS, DEFAULT_QUERY } from './po-requests-table.constants'
import { opRequestsTableStyles } from './po-requests-table.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const PORequestsTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const combos = useCombos([])
  const {
    data,
    pagination: { total_registros: totalEntries },
    loading,
    search,
    tableSelection,
    onClickTableRow,
    setSelectedTableRows,
    getTableData,
  } = usePORequests()

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getTableData(searchParams)
    } else {
      history.push(`/po-requests?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      PO_REQ_TABLE_COLUMNS.map((column) => {
        if (column.field === 'cambios_pendientes') {
          return {
            ...column,
            title: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <NotificationsNoneIcon style={{ fontSize: 18 }} />
              </span>
            ),
          }
        }
        return {
          ...column,
          title: formatMessage({ id: `pages.poRequests.table.columns.${column.field}.title` }),
          cellStyle: {
            ...opRequestsTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            !column.hasOwnProperty('multiple') && column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id)
    return poRequestsTableDataPreparation({ data, combos, intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      showSelectAllCheckbox: false,
      headerStyle: opRequestsTableStyles.header,
      tableLayout: 'fixed',
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getFormattedCell = (props) => {
    if (props.columnDef.field === 'cambios_pendientes' && props.rowData.cambios_pendientes > 0) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NotificationsActiveIcon color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    }
  }

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const mTableCellProps =
          props.columnDef.field === 'cambios_pendientes' ? { ...props, value: null } : props
        return (
          <MTableCell {...mTableCellProps} style={{ padding: 0 }}>
            {getFormattedCell(props)}
          </MTableCell>
        )
      },
    }),
    []
  )
  const handleRowClick = useCallback(
    (_, row) => {
      onClickTableRow(row['id_peticion'])
    },
    [onClickTableRow]
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
