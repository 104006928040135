import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, SelectInput } from '../../../../ui'
import { useAjaxContext } from '../../../../core/ajax'

export const SwitchTeam = ({ handleAssign, role, close }) => {
  const { formatMessage } = useIntl()
  const { get } = useAjaxContext()

  const [gestores, setGestores] = useState([])
  const [formState, setFormState] = useState({})

  const getAvailableUsers = () => {
    get(`/gestor/activos_asignacion?role=${role}`)
      .then(({ data }) => {
        const managersValues = data.map((item) => {
          return {
            key: item['id'],
            value: `${item['username']} - ${item['nombre']} ${item['apellidos']}`,
          }
        })
        setGestores(managersValues)
      })
      .catch(() => console.error('Error fetching available managers'))
  }

  useEffect(() => {
    getAvailableUsers()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSelect = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  return (
    <Box p={4}>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (!formState['gestor_id']) return
        handleAssign(formState['gestor_id'])
      }}>
        <Box mb={4}>
          <SelectInput
            name="gestor_id"
            title={`${formatMessage({ id: 'pages.applications.assignToDialog.fields.1.title' })}:`}
            value={formState['gestor_id']}
            onChange={handleChangeSelect}
            values={gestores || []}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
