import React from 'react'
import { withStyles, Box, Container } from '@material-ui/core'

import { ConsultComponent } from '../../../features/consult'
import { consultStyles } from './consult.styles'
import { useCombos } from '../../../features/combos/hooks'

export const ConsultPage = withStyles(consultStyles)(({ classes }) => {
  useCombos(['tipo_mercado', 'tipo_solicitud', 'uso_punto_suministro'])

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Box bgcolor="common.white" borderRadius={4} p={[2, 5]} mt={[1, 3]}>
          <ConsultComponent />
        </Box>
      </Container>
    </div>
  )
})
