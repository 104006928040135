import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { useApplicationUserPermitsContext } from '../../../../../pages/applications/contexts'
import { FoldingPanel, FormPanel } from '../../../../../ui'
import { useCombos } from '../../../../combos'
import { useAppContext } from '../../../contexts'
import { EditClientBtn } from '../../buttons'
import { APPLICATION_STATES } from '../../../constants'
import { CLIENT_PANEL_CONFIG, CLIENT_PANEL_STRUCTURE } from './client-panel.constants'

export const ClientPanel = () => {
  const intl = useIntl()
  const combos = useCombos([])
  const { application, updateClientInfo, disabledForUser } = useAppContext()
  const { followClient, canEditOwnClients } = useApplicationUserPermitsContext()
  const defaultDisabled = disabledForUser()

  const fieldList = useMemo(() => {
    return CLIENT_PANEL_CONFIG({ intl, combos, application })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const canEditClient =
    !defaultDisabled &&
    canEditOwnClients &&
    application['estado'] !== APPLICATION_STATES.REJECTED.KEY &&
    application['estado'] !== APPLICATION_STATES.REJECTED_2.KEY

  return (
    <FoldingPanel
      isExpanded={false}
      title={intl.formatMessage({ id: 'pages.application.detail.client.title' })}
    >
      <Box width="100%">
        <FormPanel
          {...{
            data: application,
            combos,
            block: CLIENT_PANEL_STRUCTURE({ intl, followClient, application }),
            fieldList,
          }}
        />
        {canEditClient ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <EditClientBtn {...{ application, updateClientInfo }} />
          </Box>
        ) : null}
      </Box>
    </FoldingPanel>
  )
}
